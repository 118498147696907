<!-- 入库单记录 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 入库单号 -->
      <FormItem>
        <span>入库单号：</span>
        <Input placeholder="请选择" v-model="queryFrom.order_number" clearable class="iviewIptWidth250"></Input>
      </FormItem>
      <!-- 供应商名称 -->
      <FormItem class="marginLeft40">
        <span>供应商名称：</span>
        <Select placeholder="请选择" @on-change="queryProductSelect" v-model="queryFrom.supplier_name" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in supplierSelect" :value="item.id" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span class="marginLeft40">产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <FormItem class="marginLeft40" style="margin-right: 30px !important;">
        <span>产品名称：</span>
        <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" clearable filterable placeholder="请选择" ref="name_select" v-model="queryFrom.product_name">
          <Option v-for="(item, index) in productList" :value="item" :key="index" :label="item">{{ item }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth250 marginRight60" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item" :key="index" :label="item">
            <span>{{ item }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 入库仓库 -->
      <FormItem class="marginLeft40">
        <span>入库仓库：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_name" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseList" :value="item.warehouse_name" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 入库时间 -->
      <FormItem class="marginLeft40">
        <span>入库时间：</span>
        <DatePicker type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" @on-change="changeTime"></DatePicker>
      </FormItem>
      <!-- 经手人 -->
      <FormItem class="marginLeft40">
        <span>经手人：</span>
        <Select placeholder="请选择" v-model="queryFrom.create_by" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in userList" :value="item.user_name" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!-- <FormItem>
        <span>厂家名称：</span>
        <Input placeholder="请输入" v-model="queryFrom.factory_name" class="iviewIptWidth250"></Input>
      </FormItem> -->
      <FormItem>
        <span class="label">厂家名称：</span>
        <Select filterable clearable v-model="queryFrom.factory_name" class="iviewIptWidth250">
          <Option v-for="item in nameList" :value="item.name" :key="item.subfactory_id">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!--按钮 -->
      <FormItem class="po-create-number marginRight0">
        <span class="pageBtn finger btnSure" @click="searchItem">查询</span>
        <span class="longTextBtn finger btnAdd marginLeft20" @click="exportMerge">
          <Spin style="display: inline-block;" v-show="Loading">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          合并导出
        </span>
        <span class="longTextBtn finger btnReset marginLeft20" @click="templateDownload">模板下载</span>
        <span class="longTextBtn finger btnReset marginLeft20" v-if="!disblesImg">
          <FormUploadFile label="模板导入" name="xlsx_file" :multiple="false" :apiSrc="apiSrc" @get-data="importTemplate"></FormUploadFile>
        </span>
        <span class="longTextBtn finger btnReset marginLeft20" v-if="disblesImg" @click="disableClick">模板导入</span>
      </FormItem>
    </Form>
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :columns="listColumns" :data="listData" highlight-row border :row-class-name="rowClassName" @on-sort-change="changeSort">
        <template slot-scope="{ row, index }" slot="jujiao">
          <div class="changeClass">
            <div v-show="row.jujiao" @click="clickBeiZhu(index, row.warehouse_receipt_id, row.note)">
              {{ row.note ? row.note : '-' }}
            </div>
            <div v-if="!row.jujiao">
              <Input placeholder="请输入" :ref="'input' + row.warehouse_receipt_id" v-model.trim="changeNote" @on-blur="changeNoteFun(row)" :autofocus="1 == 1"></Input>
            </div>
          </div>
        </template>
      </Table>
      <div class="pages" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
        <div class="totalNumber margin0">
          <div class="item">
            总数量：<span class="color389 marginRight20">{{ total_number }}</span>
          </div>
          <div class="item">
            总金额：<span class="color389 marginRight20">￥{{ $utils.formatMoney(total_amount) }}</span>
          </div>
          <div class="item">共计{{ total }}条记录</div>
        </div>
        <!-- <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div><div class="fr totalFont marginright30" v-if="total">合计金额: <span class="colorBlue">￥ {{ total }}</span></div><div class="fr totalFont marginright30" v-if="total">总数量: <span class="colorBlue">{{ total }}</span></div> -->
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="chaxunFlag">
        <img style="margin-top: 100px;" src="../../assets/images/erpNoData.png" />
        <p class="noDataImg">请选择查询条件进行查询</p>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0 && !chaxunFlag">
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
    </div>

    <!-- 导入确认-弹窗 -->
    <Modal v-model="analytical" :mask-closable="false" :footer-hide="true" width="90" class="modal">
      <p slot="header" class="header">导入确认</p>
      <div class="body">
        <Form inline class="formMarginBtm10">
          <FormItem>
            <span>供货单位：</span>
            <Select placeholder="请选择" v-model="analyticalTopData.supplier_name" clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in supplierSelect" :value="item.name" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <!-- <FormItem class="marginLeft40">
            <span>产品编号：</span>
            <Input placeholder="请输入" v-model.trim="analyticalTopData.order_number" class="iviewIptWidth250"></Input>
          </FormItem> -->
          <FormItem class="marginLeft20">
            <span>入库仓库：</span>
            <Select placeholder="请选择" v-model="analyticalTopData.warehouse_name" clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in warehouseList" :value="item.warehouse_name" :key="index">{{ item.warehouse_name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="marginLeft20">
            <span>订单类型：</span>
            <Select placeholder="请选择" v-model="order_type" transfer clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in orderTypeList" :value="item.type" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="marginLeft20">
            <span>采购员：</span>
            <Select placeholder="请选择" v-model="analyticalTopData.purchase_user" transfer clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in buyerList" :value="item.user_id" :key="index">{{ item.user_name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="marginLeft20">
            <span>备注：</span>
            <Input placeholder="请输入" v-model.trim="analyticalTopData.note" class="iviewIptWidth200"></Input>
          </FormItem>
          <FormItem class="marginLeft20">
            <span>入库时间：</span>
            <DatePicker @on-open-change="setTimet" :disabled="RoleId == 1 ? false : true" :editable="false" type="date" :options="options" placeholder="请选择订单日期" @on-change="changeTimer2" v-model.trim="analyticalTopData.create_time" class="iviewIptWidth200"></DatePicker>
          </FormItem>
        </Form>
        <Table :columns="analyticalList" :data="analyticalData" border :height="400">
          <!-- 数量 -->
          <template slot-scope="{ row, index }" slot="quantity">
            <!-- <Input placeholder="请输入" type="number" :value="row.quantity" @on-change="changeInput($event, 'quantity', index)"></Input> -->
            <InputNumber :max="999999" :min="0" :precision="0" v-model="row.quantity" @on-change="changeInput($event, 'quantity', index)"></InputNumber>
          </template>
          <!-- 批号 -->
          <template slot-scope="{ row, index }" slot="batch_number">
            <Input placeholder="请输入" :value="row.batch_number" @on-change="changeInput($event, 'batch_number', index)"></Input>
          </template>
          <!-- 序列号 -->
          <template slot-scope="{ row, index }" slot="serial_number">
            <Input placeholder="请输入" :value="row.serial_number" @on-change="changeInput($event, 'serial_number', index)"></Input>
          </template>
          <!-- 生产日期 -->
          <template slot-scope="{ row, index }" slot="production_date">
            <Input @on-blur="dataBlur($event, true)" @on-change="changeIpt($event, 'production_date', index)" type="text" :value="row.production_date" placeholder="请输入" style="width: 150px;" />
            <!-- <DatePicker placeholder="请输入" type="date" format="yyyy-MM-dd" :transfer="true" :value="row.production_date" @on-change="changeInput($event, 'production_date', index)"></DatePicker> -->
          </template>
          <!-- 有效期 -->
          <template slot-scope="{ row, index }" slot="valid_period">
            <Input @on-blur="dataBlur($event)" @on-change="changeIpt($event, 'valid_period', index)" type="text" :value="row.valid_period" placeholder="请输入" style="width: 150px;" />
            <!-- <DatePicker placeholder="请输入" type="date" format="yyyy-MM-dd" :transfer="true" v-model="row.valid_period" @on-change="changeInput($event, 'valid_period', index)"></DatePicker> -->
          </template>
        </Table>
        <div class="totalNumber">
          <div class="item">
            总数量:<span class="color389 marginRight20">{{ TotalQuantity }}</span>
          </div>
          <div class="item">
            总金额:<span class="color389 marginRight20">￥ {{ TotalMoney }}</span>
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="analytical = false">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure">
          <Spin v-if="loadingFlag" style="display: inline-block; color: white;">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          <span v-if="!loadingFlag" @click="sureAnalytical">确定</span>
        </span>
      </div>
    </Modal>

    <!-- 反冲确认-弹窗 -->
    <Modal v-model="isConfirm[0]" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <img src="@/assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>反冲</p>
          <p>是否确认反冲</p>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="isConfirm = [false, undefined]">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="recoil(isConfirm[1])">确定</span>
      </div>
    </Modal>
    <!-- 入库转出库-弹窗 -->
    <Modal v-model="sureWrToWd" :mask-closable="false" :footer-hide="true" width="90" class="modal" @on-cancel="noSureWrToWd()">
      <p slot="header" class="header">出库确认</p>
      <div class="body">
        <Form inline class="formMarginBtm10">
          <FormItem>
            <span>购买单位：</span>
            <Select placeholder="请选择" v-model.trim="sureWrToWdData.customer_name" clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in customerList" :value="item.customer_name" :key="index">{{ item.customer_name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="marginLeft10">
            <span>出库仓库：</span>
            <Select placeholder="请选择" v-model="sureWrToWdData.warehouse_name" clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in warehouseList" :value="item.warehouse_name" :key="index">{{ item.warehouse_name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="marginLeft20">
            <span>订单类型：</span>
            <Select placeholder="请选择" v-model="sureWrToWdData.order_type" transfer clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in orderTypeList" :value="item.type" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="marginLeft10">
            <span>销售人：</span>
            <Select placeholder="请选择" v-model="sureWrToWdData.sale_by" transfer clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in PublicSpecialUser" :value="item.id" :key="index">{{ item.user_name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="marginLeft20">
            <span>备注：</span>
            <Input placeholder="请输入" v-model.trim="sureWrToWdData.note" class="iviewIptWidth200"></Input>
          </FormItem>
          <FormItem class="marginLeft20">
            <span>订单日期：</span>
            <DatePicker @on-open-change="setTimet" :disabled="RoleId == 1 ? false : true" :editable="false" type="date" :options="options" placeholder="请选择订单日期" @on-change="changeTimer" v-model.trim="sureWrToWdData.order_time" class="iviewIptWidth200"></DatePicker>
          </FormItem>
        </Form>
        <Table :columns="sureWrToWdList" :data="getWrToWdData" border :height="400">
          <template slot-scope="{ row, index }" slot="quantity">
            <InputNumber :max="999999" :min="0" :precision="0" v-model="row.quantity" @on-change="changeInput2($event, 'quantity', index)"></InputNumber>
          </template>
          <template slot-scope="{ row, index }" slot="unit_price">
            <InputNumber :max="999999" :min="0" v-model="row.unit_price" @on-change="changeInput2($event, 'unit_price', index)"></InputNumber>
          </template>
          <template slot-scope="{ row, index }" slot="sale_tax_rate">
            <InputNumber :max="999999" :min="0" :precision="2" v-model="row.sale_tax_rate" @on-change="changeInput2($event, 'sale_tax_rate', index)"></InputNumber>
            <span style="marginleft: 5px;">%</span>
          </template>
          <template slot-scope="{ row, index }" slot="batch_number">
            <Input placeholder="请输入" :value="row.batch_number" @on-change="changeInput2($event, 'batch_number', index)"></Input>
          </template>
          <template slot-scope="{ row, index }" slot="serial_number">
            <Input placeholder="请输入" :value="row.serial_number" @on-change="changeInput2($event, 'serial_number', index)"></Input>
          </template>
          <template slot-scope="{ row, index }" slot="production_date">
            <Input @on-blur="dataBlur($event, true)" @on-change="changeIpt2($event, 'production_date', index)" type="text" :value="row.production_date" placeholder="请输入" style="width: 150px;" />
          </template>
          <template slot-scope="{ row, index }" slot="valid_period">
            <Input @on-blur="dataBlur($event)" @on-change="changeIpt2($event, 'valid_period', index)" type="text" :value="row.valid_period" placeholder="请输入" style="width: 150px;" />
          </template>
        </Table>
        <div class="totalNumber">
          <div class="item">
            总数量:<span class="color389 marginRight20">{{ totalToNum }}</span>
          </div>
          <div class="item">
            总金额:<span class="color389 marginRight20">￥ {{ totalToMoney }}</span>
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="noSureWrToWd()">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure">
          <Spin v-if="chukuloadingFlag" style="display: inline-block; color: white;">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          <span v-if="!chukuloadingFlag" @click="sureTheWrToWd">确定</span>
        </span>
      </div>
    </Modal>
    <Modal v-model="isZero" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <img src="@/assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="fl overF">
          <p style="margintop: 5px;" v-for="(item, index) in isZeroNotice" :key="index" :value="item">
            {{ item }}
          </p>
        </div>
      </div>
      <div class="foot">
        <!-- <span class="pageBtn finger btnCancle" @click="isZero = false">取消</span> -->
        <span class="pageBtn finger marginLeft20 btnSure" @click="isZero = false">确定</span>
      </div>
    </Modal>
    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
// import CustomTable from '@/components/table'
import http from '@/service/baseUrl'
import FormUploadFile from '@/components/formUploadFile.vue'
import { debounce } from '@/service/utils'
export default {
  name: 'warehouseEntryRecord',
  components: {
    // CustomTable,
    FormUploadFile,
  },
  // 计算金额数量
  computed: {
    //  总数量
    TotalQuantity() {
      let total = 0
      this.analyticalData.forEach(item => {
        total = total + item.quantity
      })
      return total
    },
    //  总数量
    totalToNum() {
      let total = 0
      this.getWrToWdData.forEach(item => {
        total = total + item.quantity
      })
      return total
    },
    // 总金额
    TotalMoney() {
      let total = 0
      this.analyticalData.forEach(item => {
        total = total + item.unit_price * item.quantity
      })
      return total.toFixed(2)
    },
    // 总金额
    totalToMoney() {
      let total = 0
      this.getWrToWdData.forEach(item => {
        total = total + item.unit_price * item.quantity
      })
      return total.toFixed(2)
    },
  },
  mounted() {
    this.quanxian = localStorage.getItem('quanxian')
    // 导入模板的时间只能让admin操作
    this.RoleId = localStorage.getItem('RoleId')
    this.getCustomerList()
    this.getPublicSpecialUser()
  },
  data() {
    return {
      sort_field: '',
      sort_type: '',
      chukuloadingFlag: false,
      loadingFlag: false,
      quanxian: '',
      PublicSpecialUser: [],
      customerList: [],
      isZero: false,
      isZeroNotice: [],
      getWrToWdData: [],
      sureWrToWdData: {
        customer_name: '',
        warehouse_id: '',
        order_type: '',
        sale_by: '',
        note: '',
        order_time: '',
      },
      listDataNote: '',
      listDataIndex: '',
      rowWarehouse_receipt_id: '',
      changeNote: '',
      tabloading: false,
      chaxunFlag: true,
      RoleId: '',
      billNumber: '',
      Loading: false,
      // 采购员下拉
      buyerList: [],
      paging: null,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 3,
      typeList: [],
      status: false,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          // maxWidth: 90,
        },
        {
          title: '入库单号',
          key: 'order_number',
          align: 'center',
          minWidth: 120,
          // maxWidth: 150,
        },
        {
          title: '供应商名称',
          key: 'supplier_name',
          align: 'center',
          minWidth: 250,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.supplier_name
              ),
            ])
          },
        },
        {
          title: '入库仓库',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 100,
          // maxWidth: 120,
        },
        {
          title: '入库时间',
          key: 'create_time',
          align: 'center',
          minWidth: 100,
          // maxWidth: 120,
        },
        {
          title: '经手人',
          key: 'create_by',
          align: 'center',
          minWidth: 80,
          // maxWidth: 100,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 100,
          // maxWidth: 120,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 120,
          render: (h, param) => {
            let str = this.$utils.formatMoney(param.row.total_amount, true)
            return h(
              'div',
              {},
              // param.row.total_amount
              str
            )
          },
        },
        {
          title: '备注',
          // key: 'note',
          slot: 'jujiao',
          sortable: 'custom',
          align: 'center',
          minWidth: 120,
          // maxWidth: 140,
          // render: (h, param) => {
          //   return h('div', [
          //     h(
          //       'div',
          //       {
          //         class: 'TextAlign_lf',
          //       },
          //       param.row.note
          //     ),
          //   ])
          // },
        },
        {
          title: '打印次数',
          key: 'prints_num',
          align: 'center',
          sortable: 'custom',
          minWidth: 80,
          // maxWidth: 140,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 180,
          fixed: 'right',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
              h(
                'span',
                {
                  style: {
                    marginLeft: '10px',
                    color: param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.output(param.row.order_number)
                    },
                  },
                },
                '导出'
              ),
              h(
                'span',
                {
                  style: {
                    marginLeft: '10px',
                    display: param.row.recoil_status == 1 ? 'none' : 'inline',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      if (this.disblesImg) {
                        this.$Message.warning('当月已关账，不可操作库存')
                        return
                      }
                      this.isConfirm = [true, param.row.warehouse_receipt_id]
                    },
                  },
                },
                '反冲'
              ),
              h(
                'span',
                {
                  style: {
                    color: param.row.related_status == 3 || param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                    marginLeft: '10px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      // this.$Message.warning('正在开发中')
                      this.clickId = param.row.warehouse_receipt_id
                      this.status = true
                      // 获取单据编号
                      this.billNumber = param.row.order_number
                      this.getTemplate()
                    },
                  },
                },
                '打印'
              ),
              h(
                'span',
                {
                  style: {
                    display: this.quanxian == 'true' ? 'inline-block' : 'none',
                    color: param.row.related_status == 3 || param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                    marginLeft: '10px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.wrToWd(param.row.warehouse_receipt_id, param.row.warehouse_name)
                    },
                  },
                },
                '转出库'
              ),
            ])
          },
        },
      ],
      clickId: null,
      // 反冲确认弹窗 + 入库单id
      isConfirm: [false, undefined],
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      // 查询参数
      queryFrom: {
        product_code: '',
        order_number: '', // 入库单号
        supplier_name: '', // 供应商id
        warehouse_name: '', // 入库仓库
        create_time_start: '', // 起始入库时间
        create_time_end: '', // 截止入库时间
        create_by: '', // 经手人
        product_id: '',
        product_code_array: [],
        factory_name:''
      },
      // 搜索条件
      searchObj: {},
      // 供应商下拉
      supplierSelect: [],
      // 经手人列表
      userList: [],
      // 仓库下拉
      warehouseList: [],
      sureWrToWd: false,
      // 导入确认弹窗
      analytical: false,
      // 导入接口
      apiSrc: '',
      // 订单类型
      order_type: '',
      // 订单类型下拉
      orderTypeList: [
        { name: '普通购销', type: 1 },
        { name: '自寄售', type: 3 },
        { name: '供方寄售', type: 4 },
        { name: '委托代销', type: 2 },
      ],
      // 待确认头部数据
      analyticalTopData: [],
      // 待确认表格数据
      analyticalData: [],
      // 待确认表头
      analyticalList: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 55,
          maxWidth: 90,
        },
        {
          title: '产品编码',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          // slot: 'product_name',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
          maxWidth: 300,
        },
        {
          title: '规格型号',
          // slot: 'model_name',
          key: 'model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单位',
          // slot: 'unit',
          key: 'unit',
          align: 'center',
          minWidth: 70,
        },
        {
          title: '数量',
          slot: 'quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单价',
          // slot: 'unit_price',
          key: 'unit_price',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.unit_price, true))
          },
        },
        {
          title: '金额',
          // slot: 'amount',
          key: 'amount',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.amount, true))
          },
        },
        {
          title: '批号',
          slot: 'batch_number',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '序列号',
          slot: 'serial_number',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '生产日期',
          slot: 'production_date',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '有效期',
          slot: 'valid_period',
          key: 'valid_period',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '生产厂家',
          // slot: 'factory_name',
          key: 'factory_name',
          align: 'center',
          minWidth: 250,
          maxWidth: 350,
        },
        {
          title: '注册证号/备案凭证号',
          // slot: 'licence_code',
          key: 'licence_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '注册证有效期',
          // slot: 'licence_valid_period',
          key: 'licence_valid_period',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '储运条件',
          // slot: 'storage_condition',
          key: 'storage_condition',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 120,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.addAnalytical(param.index)
                    },
                  },
                },
                '新增'
              ),
              h(
                'span',
                {
                  class: 'tableFont marginLeft20',
                  on: {
                    click: () => {
                      this.delAnalytical(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      sureWrToWdList: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 55,
          maxWidth: 90,
        },
        {
          title: '产品编码',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
          maxWidth: 300,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          minWidth: 70,
        },
        {
          title: '数量',
          slot: 'quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单价(¥)',
          slot: 'unit_price',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '销售税率(%)',
          slot: 'sale_tax_rate',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '金额',
          key: 'amount',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.amount, true))
          },
        },
        {
          title: '批号',
          slot: 'batch_number',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '序列号',
          slot: 'serial_number',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '生产日期',
          slot: 'production_date',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '有效期/失效期',
          slot: 'valid_period',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 250,
          maxWidth: 350,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '注册证有效期',
          key: 'licence_valid_period',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '储运条件',
          key: 'storage_condition',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 120,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.addSureWrToWd(param.index)
                    },
                  },
                },
                '新增'
              ),
              h(
                'span',
                {
                  class: 'tableFont marginLeft20',
                  on: {
                    click: () => {
                      this.delSureWrToWd(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      trime: null,
      productList: [],
      modelList: [],
      disblesImg: false,
      total_amount: 0,
      total_number: 0,
      options: {},
      nameList: [],
      // totalToMoney: null,
      // totalToNum: null,
    }
  },
  activated() {
    this.inventoryLook()
    this.getSelect()
  },
  methods: {
    changeSort(column) {
      if (column.column.title == '备注') {
        this.sort_field = 'note'
      } else if (column.key && column.key == 'prints_num') {
        this.sort_field = 'prints_num'
      }
      if (column.order == 'normal') {
        this.sort_type = ''
      } else {
        this.sort_type = column.order
      }
      this.pages.page = 1
      this.getTableList(this.pages, this.searchObj)
    },
    noSureWrToWd() {
      this.sureWrToWd = false
      this.sureWrToWdData = {
        customer_name: '',
        warehouse_id: '',
        order_type: '',
        sale_by: '',
        note: '',
        order_time: '',
      }
    },
    // 客户名称下拉
    getCustomerList() {
      this.$http.get(this.$api.customerList).then(res => {
        this.customerList = res.data
      })
    },
    // 销售人下拉
    getPublicSpecialUser() {
      this.$http.get(this.$api.getPublicSpecialUser, { user_type: '2' }).then(res => {
        this.PublicSpecialUser = res.data
      })
    },
    sureTheWrToWd() {
      // 确认导入
      if (!this.sureWrToWdData.customer_name) {
        this.$Message.warning(`请完善 购买单位`)
        return
      }

      if (!this.sureWrToWdData.warehouse_name) {
        this.$Message.warning(`请完善 仓库信息`)
        return
      }
      if (!this.sureWrToWdData.sale_by) {
        this.$Message.warning(`请选择销售人`)
        return
      }
      if (!this.sureWrToWdData.order_time) {
        this.$Message.warning(`请选择订单日期`)
        return
      }
      if (!this.sureWrToWdData.order_type) {
        this.$Message.warning(`请完善 订单类型`)
        return
      }
      if (this.getWrToWdData.length == 0) {
        this.$Message.warning('请至少添加一组产品再提交')
        return
      }
      for (let index = 0; index < this.getWrToWdData.length; index++) {
        if (!this.getWrToWdData[index].quantity && this.getWrToWdData[index].quantity == 0) {
          this.$Message.warning(`请完善第${index + 1}组产品的 数量`)
          return
        }
        if (this.getWrToWdData[index].production_date) {
          let resCode = this.$utils.dataType(this.getWrToWdData[index].production_date)
          if (resCode.code) {
            this.$Message.warning(resCode.text)
            return
          }
        }
        if (!this.getWrToWdData[index].batch_number) {
          this.$Message.warning(`请完善第${index + 1}组产品的 批号`)
          return
        }
        if (!this.getWrToWdData[index].valid_period) {
          this.$Message.warning(`请完善第${index + 1}组产品的 有效期/失效期`)
          return
        }
        this.getWrToWdData[index].no = index + 1
      }
      let dataArr = []
      this.isZeroNotice = []
      // 当前面的判断都过了之后，要进行所有数据的单价是否为0的判断
      for (let index2 = 0; index2 < this.getWrToWdData.length; index2++) {
        if (!this.getWrToWdData[index2].unit_price) {
          dataArr.push({
            index: index2,
            tips: '第' + (index2 + 1) + '组产品的' + (!this.getWrToWdData[index2].unit_price ? '单价不能为0,' : '') + '请修改为0.01。',
          })
          if (index2 == this.getWrToWdData.length - 1) {
            for (let m = 0; m < dataArr.length; m++) {
              this.isZeroNotice.push(dataArr[m].tips)
            }
            this.isZero = true
            return
          }
        }
      }
      this.chukuloadingFlag = true
      this.sureWrToWdData.order_time = this.sureWrToWdData.order_time ? this.$moment(this.sureWrToWdData.order_time).format('YYYY-MM-DD') : null
      let data = this.sureWrToWdData
      data.order_type = this.sureWrToWdData.order_type
      data.line_info = this.getWrToWdData
      if (this.sureWrToWdData.warehouse_name) {
        this.warehouseList.forEach(item => {
          if (item.warehouse_name === this.sureWrToWdData.warehouse_name) {
            data.warehouse_id = item.warehouse_id
          }
        })
      }
      this.$http.post(this.$api.gspWrToWd, data, true).then(res => {
        this.chukuloadingFlag = false
        if (res.message != 'success') {
          this.$Message.warning({
            background: true,
            content: res.message,
            closable: true,
            duration: 3000,
          })
        } else {
          this.$Message.success({
            background: true,
            content: '生成出库单成功',
            closable: true,
            duration: 2,
          })
          this.sureWrToWd = false
          ;(this.sureWrToWdData = {
            customer_name: '',
            warehouse_id: '',
            order_type: '',
            sale_by: '',
            note: '',
            order_time: '',
          }),
          this.getTableList(this.pages, this.searchObj)
        }
      }, err => {
          this.chukuloadingFlag = false
      })
    },
    wrToWd(id, theName) {
      if (theName) {
        this.sureWrToWdData.warehouse_name = theName
      }
      this.sureWrToWdData.order_time = this.$moment(new Date()).format('YYYY-MM-DD')
      this.$http.get(this.$api.gspWrToWdDetail, { warehouse_receipt_id: id }).then(res => {
        if (res.status) {
          this.getWrToWdData = res.data
          this.sureWrToWd = true
        }
      })
    },
    changeNoteFun() {
      if (this.listDataNote == this.changeNote) {
        this.$set(this.listData[this.listDataIndex], 'jujiao', true)
        return
      }
      this.$http
        .put(this.$api.wareHouseReceipt, {
          warehouse_receipt_id: this.rowWarehouse_receipt_id,
          note: this.changeNote ? this.changeNote : '',
        })
        .then(res => {
          if (res.status) {
            this.$Message.success('修改成功')
            this.getTableList(this.pages, this.searchObj)
          }
        })
    },
    clickBeiZhu(index, id, note) {
      this.rowWarehouse_receipt_id = id
      this.listDataIndex = index
      this.listDataNote = note
      this.changeNote = JSON.parse(JSON.stringify(note))
      this.$set(this.listData[index], 'jujiao', false)
      this.$set(this.listData[index], 'note', this.listDataNote)
      let data = 'input' + id
      setTimeout(() => {
        this.$refs[data].$el.children.forEach(item => {
          if (item.nodeName == 'INPUT') {
            this.$nextTick(() => {
              document.getElementsByClassName('ivu-input ivu-input-default')[3].focus()
            })
          }
        }, 500)
      })
    },
    setTimet() {
      let that = this
      that.options = {
        disabledDate(date) {
          return date && date.valueOf() > new Date(that.$moment().format('YYYY-MM-DD'))
        },
      }
    },
    changeTimer(e) {
      if (e) {
        this.analyticalTopData.create_time = e
      }
    },
    changeTimer2() {
      if (e) {
        this.sureWrToWdData.create_time = e
      }
    },
    disableClick() {
      if (this.disblesImg) {
        this.$Message.warning('当月已关账，不可操作库存')
        return
      }
    },
    // 新增界面禁用功能
    inventoryLook() {
      this.$http.get(this.$api.inventoryLook, {}).then(res => {
        if (res.data === 1) {
          this.disblesImg = true
        }
      })
    },
    // 获取供应商下的产品下拉选择
    queryProductSelect(id) {
      this.$http.get(this.$api.receiptPrductModelName, { supplier_id: id }, false).then(res => {
        this.productList = res.data.product_name_list
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = ''
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(name) {
      this.$http.get(this.$api.receiptPrductModelName, { supplier_id: this.queryFrom.supplier_id, product_name: name }, false).then(res => {
        this.modelList = res.data.model_name_list
      })
    },
    dataBlur(e, testing) {
      if (testing && !e.target.value) return
      let res = this.$utils.dataType(e.target.value)
      if (res.code) {
        this.$Message.warning(res.text)
      }
    },
    // 输入框改变
    changeIpt(e, name, index) {
      this.$set(this.analyticalData[index], name, e.target.value.trim())
    },
    changeIpt2(e, name, index) {
      this.$set(this.getWrToWdData[index], name, e.target.value.trim())
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$api.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$api.recentTemplate, { template_type: this.template_type }, false).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    surePrint: debounce(
      function () {
        let api_info = `${this.$api.inputOrderRecodeDetail.url}?warehouse_receipt_id=${this.clickId}`
        // for (let objName in this.queryFrom) {
        //   console.log(objName)
        //   if (this.queryFrom[objName]) {
        //     api_info = api_info + `${objName}=${this.queryFrom[objName]}&`
        //   }
        // }
        if (!this.template_type) {
          this.$Message.warning('请选择模版类型')
          return
        }
        if (!this.template_id) {
          this.$Message.warning('请选择模版名称')
          return
        }
        this.status = false
        this.$http.downFile(this.$api.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
          this.$Message.success('导出成功')
          let blob = new Blob([res.data], { type: 'application/x-xlsx' })
          const aLink = document.createElement('a')
          aLink.href = URL.createObjectURL(blob)
          aLink.download = `${this.billNumber}.xlsx`
          aLink.click()

          // 打印完刷新列表
          this.getTableList(this.pages, this.searchObj)
        })
      },
      500,
      true
    ),
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$api.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.recoil_status == 1) return 'redRow'
      return ''
    },
    // 反冲
    recoil(id) {
      this.$http.get(this.$api.warehouseReceiptRecoil, { warehouse_receipt_id: id }, true).then(res => {
        if (res.status) {
          this.$Message.success('反冲成功')
          //this.getTableList(this.pages, {})
          this.getTableList(this.pages, this.searchObj)
        }
      })
      this.isConfirm = [false, undefined]
    },
    // 详情
    details(row) {
      this.$router.push({
        path: '/detailsWarehouseEntryRecord',
        query: {
          warehouse_receipt_id: row.warehouse_receipt_id,
          recoil_status: row.recoil_status,
        },
      })
    },
    /* 将时间数组绑定到查询条件里 */
    changeTime(val) {
      if (val.length != 0) {
        this.$set(this.queryFrom, 'create_time_start', val[0])
        this.$set(this.queryFrom, 'create_time_end', val[1])
      } else {
        this.$set(this.queryFrom, 'create_time_start', null)
        this.$set(this.queryFrom, 'create_time_end', null)
      }
    },
    /* 获取表格数据 */
    getTableList(pages, searchItem) {
      this.tabloading = true
      let obj = {}
      Object.assign(obj, pages, searchItem)
      if (this.sort_type) {
        ;(obj.sort_type = this.sort_type), (obj.sort_field = this.sort_field)
      }
      console.log(obj);
      this.$http.get(this.$api.inputOrderRecode, obj, false).then(res => {
        this.tabloading = false
        if (res.status) {
          this.listData = res.data.result
          this.total_number = res.data.total_number
          this.total_amount = res.data.total_amount
          this.total = res.data.total
          for (const item of this.listData) {
            item.create_time = item.create_time ? this.$moment.unix(item.create_time).format('YYYY-MM-DD') : null
          }
          this.listData.forEach(item => {
            this.$set(item, 'jujiao', true)
          })
        }
      })
    },
    /* 分页方法 */
    changePage(e) {
      this.pages.page = e
      this.getTableList(this.pages, this.searchObj)
    },
    /* 搜索产品 */
    searchItem() {
      this.chaxunFlag = false
      let obj = {}
      obj.order_number = this.queryFrom.order_number
      obj.product_code = this.queryFrom.product_code
      // obj.supplier_name = this.queryFrom.supplier_name
      obj.supplier_id = this.queryFrom.supplier_name
      obj.warehouse_name = this.queryFrom.warehouse_name
      obj.create_time_start = this.queryFrom.create_time_start
      obj.create_time_end = this.queryFrom.create_time_end
      obj.create_by = this.queryFrom.create_by
      obj.product_name = this.queryFrom.product_name
      obj.model_name = this.queryFrom.product_code_array ? this.queryFrom.product_code_array.join(',') : ''
      obj.factory_name = this.queryFrom.factory_name
      this.searchObj = obj
      this.pages = { page: 1, rows: 10 }
      this.getTableList(this.pages, obj)
    },
    /* 获取下拉列表 */
    getSelect() {
      // 获取供应商下拉
      this.$http.get(this.$api.getSupplierNameList).then(res => {
        if (res.status) {
          this.supplierSelect = res.data
        }
      })
      // 仓库下拉
      let data = {
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWareHouseList, data, false).then(res => {
        this.warehouseList = res.data
      })
      // 经手人下拉
      this.$http.get(this.$api.publicSpecialUser, { user_type: '1' }, false).then(res => {
        this.userList = res.data
      })
      // 获取采购员
      this.$http.get(this.$api.buyerSelect, {}, false).then(res => {
        this.buyerList = res.data
      })
    },
    // 合并导出
    exportMerge() {
      let supplier_id = ''
      this.supplierSelect.forEach(item => {
        if (item.name == this.queryFrom.supplier_name) {
          supplier_id = item.id
        }
      })
      let data = {
        order_number: this.queryFrom.order_number,
        product_code: this.queryFrom.product_code,
        create_time_start: this.queryFrom.create_time_start,
        create_time_end: this.queryFrom.create_time_end,
        create_by: this.queryFrom.create_by,
        warehouse_name: this.queryFrom.warehouse_name,
        supplier_id: supplier_id,
      }
      this.Loading = true
      this.$http.downFile(this.$api.gspWarehouseRecrpitBatchExport, this.searchObj).then(res => {
        this.Loading = false
        let blob = new Blob([res.data], { type: 'application/zip' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '入库单记录合并导出表.zip'
        aLink.click()
      })
    },
    // 导出
    output(order_number) {
      let data = {
        order_number: order_number,
      }
      this.$http.downFile(this.$api.gspWarehouseReceiptExport, data).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '入库单记录导出表.xlsx'
        aLink.click()
      })
    },
    // 模板下载
    templateDownload() {
      this.$http.downFile(this.$api.receiptDownload).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '入库单导入模板.xlsx'
        aLink.click()
      })
    },
    // 模板导入
    importTemplate(res) {
      let that = this
      this.analytical = true
      // let date = this.$moment(new Date()).format('YYYY-MM-DD')
      let today = that.$moment(new Date()).format('YYYY-MM-DD')
      this.analyticalTopData = {
        supplier_name: res.supplier_name,
        order_number: res.order_number,
        warehouse_name: res.warehouse_name,
        create_time: today,
        // create_time: res.create_time,
        note: res.note,
      }
      console.log(today, this.analyticalTopData)
      for (const item of res.lines_info) {
        item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : null
        item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : null
        // 判断是录入的是年月还是年月日
        item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period).replace(/-/gi, '') : null
        item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : null
        item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date).replace(/-/gi, '') : null
      }
      this.analyticalData = res.lines_info
    },
    // 确认导入
    sureAnalytical() {
      // 防抖
      if (this.trime) {
        clearTimeout(this.trime)
        this.trime = null
        return
      }
      // 校验
      if (!this.analyticalTopData.supplier_name) {
        this.$Message.warning(`请完善 供货单位`)
        return
      }
      // if (!this.analyticalTopData.order_number) {
      //   this.$Message.warning(`请完善 产品编号`)
      //   return
      // }
      if (!this.analyticalTopData.warehouse_name) {
        this.$Message.warning(`请完善 入库仓库`)
        return
      }
      if (!this.analyticalTopData.create_time) {
        this.$Message.warning(`请选择 入库时间`)
        return
      }
      if (!this.order_type) {
        this.$Message.warning(`请完善 订单类型`)
        return
      }
      if (this.analyticalData.length == 0) {
        this.$Message.warning('请至少添加一组产品再提交')
        return
      }
      if (!this.analyticalTopData.purchase_user) {
        this.$Message.warning(`请选择采购员`)
        return
      }
      let numberList = [] // 数量
      let batchNumber = [] // 批号
      let yesTime = [] // 有效时间
      for (let index = 0; index < this.analyticalData.length; index++) {
        if (this.analyticalData[index].production_date) {
          let resCode = this.$utils.dataType(this.analyticalData[index].production_date)
          if (resCode.code) {
            this.$Message.warning(resCode.text)
            return
          }
        }
        if (this.analyticalData[index].valid_period) {
          let resCode2 = this.$utils.dataType(this.analyticalData[index].valid_period)
          if (resCode2.code) {
            this.$Message.warning(resCode2.text)
            return
          }
        }

        if (!this.analyticalData[index].quantity && this.analyticalData[index].quantity != 0) {
          numberList.push(index + 1)
        }
        if (!this.analyticalData[index].batch_number) {
          batchNumber.push(index + 1)
        }
        /* if (!this.analyticalData[index].production_date) {
          this.$Message.warning(`请完善第${index + 1}组产品的 生产日期`)
          return
        } */
        if (!this.analyticalData[index].valid_period) {
          yesTime.push(index + 1)
        }
      }
      // 提示未填写完整的所有数据
      if (numberList.length != 0) {
        this.$Message.warning(`请完善第${numberList.join(',')}组产品的 数量`)
        return
      } else if (batchNumber.length != 0) {
        this.$Message.warning(`请完善第${batchNumber.join(',')}组产品的 批号`)
        return
      } else if (yesTime.length != 0) {
        this.$Message.warning(`请完善第${yesTime.join(',')}组产品的 有效期`)
        return
      }
      this.loadingFlag = true
      let addArr = []
      for (const item of this.analyticalData) {
        let obj = {}
        obj.amount = item.amount
        obj.batch_number = item.batch_number
        obj.factory_name = item.factory_name
        obj.licence_code = item.licence_code
        obj.licence_valid_period = item.licence_valid_period
        obj.packing_size = item.packing_size
        obj.valid_days = item.valid_days
        obj.model_name = item.model_name
        obj.product_model_code = item.product_model_code
        obj.production_date = item.production_date ? item.production_date : null
        obj.serial_number = item.serial_number
        obj.storage_condition = item.storage_condition
        obj.unit = item.unit
        obj.unit_price = item.unit_price
        obj.valid_period = item.valid_period
        obj.name = item.product_name
        obj.number = item.quantity
        obj.product_model_code_parent = item.product_model_code_parent
        obj.product_model_code_child = item.product_model_code_child
        obj.note = 'note'
        addArr.push(obj)
      }
      // con
      this.analyticalTopData.lines_info = addArr
      this.analyticalTopData.create_time = this.$moment(this.analyticalTopData.create_time).format('YYYY-MM-DD')
      let addObj = this.analyticalTopData
      addObj.lines_info.forEach(item => {
        if (typeof item.amount == 'string') {
          item.amount = parseFloat(item.amount.substring(1, item.amount.length))
        }
      })
      addObj.deal_type = this.order_type
      this.trime = setTimeout(() => {
        this.$http
          .post(this.$api.receiptSure, addObj, true)
          .then(res => {
            if (res.status) {
              this.$Message.success({
                background: true,
                content: '生成入库单成功',
                closable: true,
                duration: 2,
              })
              this.loadingFlag = false
              this.analytical = false
              this.getTableList(this.pages, this.searchObj)
              this.trime = null
            }
          })
          .catch(err => {
            this.trime = null
            this.loadingFlag = false
          })
      }, 500)
    },
    // 修改记录
    changeInput(e, field, index) {
      switch (field) {
        case 'quantity': // 数量
          this.$set(this.analyticalData[index], field, e * 1)
          let total = this.analyticalData[index].quantity * this.analyticalData[index].unit_price
          this.$set(this.analyticalData[index], 'amount', total > 0 && total ? '¥' + total.toFixed(2) : '¥' + 0)
          break
        case 'production_date': // 生产日期
        case 'valid_period': // 有效期/失效期
        case 'licence_valid_period': // 注册证有效期
          this.analyticalData[index][field] = e
          break
        default:
          this.analyticalData[index][field] = e.target.value.trim()
          break
      }
    },
    // 修改记录
    changeInput2(e, field, index) {
      switch (field) {
        case 'quantity': // 数量
          this.$set(this.getWrToWdData[index], field, e * 1)
          let total = this.getWrToWdData[index].quantity * this.getWrToWdData[index].unit_price
          this.$set(this.getWrToWdData[index], 'amount', total > 0 && total ? '¥' + total.toFixed(2) : '¥' + 0)
          break
        case 'production_date': // 生产日期
        case 'valid_period': // 有效期/失效期
        case 'licence_valid_period': // 注册证有效期
          this.getWrToWdData[index][field] = e
          break
        default:
          this.getWrToWdData[index][field] = e.target.value.trim()
          break
      }
    },
    changeInput2(e, field, index) {
      if (typeof e == 'object' && e) {
        e = e.target.value
      }
      function foo(e, that) {
        if (e * 1 < 0) {
          that.$set(that.getWrToWdData[index], field, 1)
        } else {
          that.getWrToWdData[index][field] = e.trim() * 1
        }
        // 计算金额
        if (isNaN(that.getWrToWdData[index].quantity) || isNaN(that.getWrToWdData[index].unit_price)) {
          that.getWrToWdData[index].amount = 0
        } else {
          that.getWrToWdData[index].amount = '¥' + (that.getWrToWdData[index].quantity * that.getWrToWdData[index].unit_price).toFixed(2)
        }
      }
      switch (field) {
        case 'quantity': // 数量
          this.$set(this.getWrToWdData[index], field, e * 1)
          let total = this.getWrToWdData[index].quantity * this.getWrToWdData[index].unit_price
          this.$set(this.getWrToWdData[index], 'amount', total > 0 && total ? '¥' + total.toFixed(2) : '¥' + 0)
          break
        case 'unit_price': // 单价
          this.$set(this.getWrToWdData[index], field, e * 1)
          if (e) {
            this.$nextTick(() => {
              this.getWrToWdData[index].unit_price = Number(e.toFixed(2))
            })
          }
          let total2 = this.getWrToWdData[index].quantity * this.getWrToWdData[index].unit_price
          this.$set(this.getWrToWdData[index], 'amount', total2 > 0 && total2 ? '¥' + total2.toFixed(2) : '¥' + 0)
          this.getWrToWdData[index][field] = e
          break
        case 'sale_tax_rate': // 销售税率
          if (e || e == 0) {
            this.$set(this.getWrToWdData[index], field, e * 1)
          } else {
            this.$set(this.getWrToWdData[index], field, null)
          }
          break
        // case 'unit_price': // 单价
        // case 'amount': // 金额
        //   foo(e, this)
        //   break;
        case 'production_date': // 生产日期
        case 'valid_period': // 有效期/失效期
        case 'licence_valid_period': // 注册证有效期

        default:
          this.getWrToWdData[index][field] = e.trim()
          break
      }
    },
    // 删除记录
    delAnalytical(index) {
      this.analyticalData.splice(index, 1)
    },
    // 新增记录
    addAnalytical(index) {
      this.analyticalData.push(JSON.parse(JSON.stringify(this.analyticalData[index])))
    },
    addSureWrToWd(index) {
      this.getWrToWdData.push(JSON.parse(JSON.stringify(this.getWrToWdData[index])))
    },
    delSureWrToWd(index) {
      this.getWrToWdData.splice(index, 1)
    },
    // 获取厂家下拉列表
    getListSupList() {
      this.$http.get(this.$api.factoryListBusiness, true).then(res => {
        this.nameList = res.data
      })
    },
  },
  created() {
    // this.getTableList(this.pages, {})
    this.getSelect()
    this.apiSrc = http.http + this.$api.receiptImport.url
    this.queryProductSelect()
    this.queryProductCodeSelect()
    this.inventoryLook()
    this.getListSupList();
  },
}
</script>

<style scoped lang="less">
// 表格
.tabDiv {
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}

// 反冲确认弹窗
.foot {
  text-align: right;
}
.topModal {
  padding-bottom: 56px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .flDiv {
    p:nth-child(1) {
      display: block;
      height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      margin-bottom: 7px;
    }
    p:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}

// 导入确认弹窗
.modal {
  .money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      padding-right: 8px;
    }
  }

  .foot {
    text-align: right;
    margin-top: 20px;
  }
}

.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.margin0 {
  margin: 0px;
}
.marginLeft20 {
  margin-left: 20px;
}
.iviewIptWidth200 {
  width: 180px;
}
.marginright30 {
  margin-right: 30px;
  .colorBlue {
    color: #389afc;
  }
}
.noDataImg {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateX(-54%) translateY(-50%);
  font-size: 22px;
  color: #ccc;
}
.changeClass {
  cursor: pointer;
}
</style>
